import { shopifyFetch } from "./fetch.js";

const shopifyCartQuerySettings = `
  id
  createdAt
  updatedAt
  checkoutUrl
  lines(first:10) {
    edges {
      node {
        id
        cost {
          totalAmount {
            amount
          }
          subtotalAmount {
            amount
          }
          amountPerQuantity {
            amount
          }
        }
        quantity
        discountAllocations {
          ... on CartAutomaticDiscountAllocation {
            title
            discountedAmount {
              currencyCode
              amount
            }
          }
        }
        merchandise {
          ... on ProductVariant {
            id
            sku
            cartImage:
              image {
                id
                url(transform: {maxWidth:100, maxHeight:100})
                altText
              }
            cartImage2x:
              image {
                id
                url(transform: {maxWidth:100, maxHeight:100, scale:2})
              }
            title
            priceV2 {
              amount
              currencyCode
            }
            product {
              id
              title
            }
          }
        }
      }
    }
  }
  estimatedCost {
    totalAmount {
      amount
      currencyCode
    }
    subtotalAmount {
      amount
      currencyCode
    }
    totalTaxAmount {
      amount
      currencyCode
    }
    totalDutyAmount {
      amount
      currencyCode
    }
  }
`;

const createCartWithItem = async ({ itemId, parsedQuantity }) => {
  try {
    const shopifyResponse = await shopifyFetch({
      query: `
        mutation createCart($cartInput: CartInput) {
          cartCreate(input: $cartInput) {
            cart {
              ${shopifyCartQuerySettings}
            }
            userErrors {
              field
              message
            }
          }
        }
      `,
      variables: {
        cartInput: {
          lines: [
            {
              quantity: parsedQuantity,
              merchandiseId: itemId,
            },
          ],
        },
      },
    });
    return shopifyResponse;
  } catch (error) {
    console.log(error);
  }
};

const getCart = async (cartId) => {
  try {
    const shopifyResponse = await shopifyFetch({
      query: `
        query getCart($cartId: ID!) {
          cart(id: $cartId) {
            ${shopifyCartQuerySettings}
          }
        }
      `,
      variables: {
        cartId,
      },
    });
    Alpine.store("cart").cartDetails = shopifyResponse.data.cart;
    return shopifyResponse;
  } catch (error) {
    console.log("Error fetching cart", error);
    return null;
  }
};

const addItemToCart = async ({ cartId, itemId, parsedQuantity }) => {
  try {
    const shopifyResponse = await shopifyFetch({
      query: `
        mutation addItemToCart($cartId: ID!, $lines: [CartLineInput!]!) {
          cartLinesAdd(cartId: $cartId, lines: $lines) {
            cart {
              ${shopifyCartQuerySettings}
            }
            userErrors {
              field
              message
            }
          }
        }
      `,
      variables: {
        cartId,
        lines: [
          {
            merchandiseId: itemId,
            quantity: parsedQuantity,
          },
        ],
      },
    });
    return shopifyResponse;
  } catch (error) {
    console.log(error);
  }
};

const removeItemFromCart = async ({ cartId, lineId }) => {
  try {
    const shopifyResponse = await shopifyFetch({
      query: `
        mutation removeItemFromCart($cartId: ID!, $lineIds: [ID!]!) {
          cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
            cart {
              ${shopifyCartQuerySettings}
            }
            userErrors {
              field
              message
            }
          }
        }
      `,
      variables: {
        cartId,
        lineIds: [lineId],
      },
    });
    return shopifyResponse.data;
  } catch (error) {
    console.log(error);
  }
};

const updateCart = async ({ cartId, lineId, quantity }) => {
  try {
    const shopifyResponse = await shopifyFetch({
      query: `
        mutation updateCart($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
          cartLinesUpdate(cartId: $cartId, lines: $lines) {
            cart {
              ${shopifyCartQuerySettings}
            }
            userErrors {
              field
              message
            }
          }
        }
      `,
      variables: {
        cartId,
        lines: [
          {
            id: lineId,
            quantity,
          },
        ],
      },
    });
    return shopifyResponse;
  } catch (error) {
    console.log(error);
  }
};

export { addItemToCart, createCartWithItem, getCart, removeItemFromCart, updateCart };