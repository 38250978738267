import { shopifyFetch } from "./fetch.js";

export const getProductById = async (id) => {
  const response = await shopifyFetch({
    query: `
        query getProduct($id: ID!) {
          product(id: $id) {
            title
            id
            handle
            descriptionHtml
            variants(first: 50) {
              edges {
                node {
                  id
                  title
                  priceV2 {
                    amount
                  }
                  compareAtPrice {
                    amount
                  }
                  selectedOptions {
                    name,
                    value
                  }
                }
              }
            }
          }
        }
      `,
    variables: {
      id,
    },
  });
  return response.data;
};

export const getVariantByOptions = async (id, values) => {
  const response = await shopifyFetch({
    query: `
        query getVariant($id: ID!, $values: [SelectedOptionInput!]!) {
          node(id: $id) {
                ... on Product{
                  title
                  handle
                  variantBySelectedOptions(selectedOptions: $values) {
                    availableForSale
                    title
                    id
                    priceV2 {
                      amount
                    }
                    compareAtPrice {
                      amount
                    }
                    image {
                      id
                    }
                }
              }
            }
          }
        `,
    variables: {
      id,
      values,
    },
  });
  return response.data;
};
