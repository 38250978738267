// 'use strict';
import { slugify, reverseSlugify, formatCurrency } from "./utility";
import {
  addItemToCart,
  createCartWithItem,
  getCart,
  removeItemFromCart,
  updateCart,
} from "./shopify/cart.js";
import { itemAdded, itemRemoved } from "./analytics.js";
import { getVariantByOptions, getProductById } from "./shopify/product.js";

// Listen for alpine to be ready
document.addEventListener("alpine:init", () => {
  Alpine.store("cart", {
    // Check if cart ID exists on Alpine init
    async init() {
      const shopifyCartId = JSON.parse(
        window.localStorage.getItem("shopifyCartId")
      );

      if (shopifyCartId && shopifyCartId.id) {
        try {
          // ping shopify for the cart
          let cartResponse = await getCart(shopifyCartId.id);

          if (!cartResponse?.data?.cart || cartResponse.errors) {
            // ID was incorrect or expired
            this.cartId.id = null;
            this.cartId.subtotal = null;
            this.cartId.totalQuantity = 0;

            this.cartDetails = {};
          } else {
            if (this.cartTotalQuantity() === 0) {
              this.cartAvailable = false;
            } else {
              this.cartAvailable = true;
            }
          }
        } catch (error) {
          // not a sufficient response from Shopify, wiping local storage
          this.cartId.id = null;
          this.cartId.subtotal = null;
          this.cartId.totalQuantity = null;
        }
      }
    },
    firstClickHandled: false,
    cartAvailable: false,
    freeShipping: 50,
    open: false,
    classes: false,
    cartDetails: {},
    loading: false,
    recentlyAddedProductID: "",
    cartId: Alpine.$persist({
      id: null,
      subtotal: null,
      totalQuantity: 0,
    }).as("shopifyCartId"),

    getCurrentQuantity(index) {
      return this.cartDetails.lines?.edges[index]?.node?.quantity;
    },

    getDiscounts(product) {
      let title = product.node.discountAllocations[0]?.title;
      let amount = product.node.discountAllocations[0]?.discountedAmount.amount;

      if(product.node.discountAllocations[0]) {
        return {
          title: title,
          amount: (amount ? `-${Alpine.store("utility").formatCurrencyString(amount)}` : null),
        }
      }
      return {
        title: null,
        amount: null
      }
    },

    getItemInfo(product) {
      const getTitle = () => {
        let title;
        if (product.node.merchandise.title === "Default Title") {
          title = `${product.node.merchandise.product.title}`;
        } else {
          title = `${product.node.merchandise.product.title}<span class="item-title-variant">${product.node.merchandise.title}</span>`;
        }
        return title;
      };

      const getClass = () => {
        return this.recentlyAddedProductID === product?.node?.merchandise.id
          ? "animated-item"
          : "";
      };

      const getSrc = (product) => {
        return product?.node?.merchandise?.cartImage?.url;
      };

      const getSrcSet = () => {
        return `${product?.node?.merchandise?.cartImage2x?.url} 2x`;
      };

      const getAlt = () => {
        return product?.node?.merchandise?.cartImage?.altText || "product";
      };

      const getPrice = () => {
        return `(${Alpine.store("utility").formatCurrencyString(
          product?.node.merchandise.priceV2.amount
        )} each)`;
      };

      const getTotal = () => {
        return Alpine.store("utility").formatCurrencyString(
          product?.node.merchandise.priceV2.amount * product?.node?.quantity
        );
      };

      const getCost = () => {
        return {
          subtotal: Alpine.store("utility").formatCurrencyString(product?.node?.cost.subtotalAmount.amount),
          total: Alpine.store("utility").formatCurrencyString(product?.node?.cost.totalAmount.amount),
          unit: Alpine.store("utility").formatCurrencyString(product?.node?.cost.amountPerQuantity.amount),
          discountedAmount: (product?.node?.cost.subtotalAmount.amount !== product?.node?.cost.totalAmount.amount)
        }
      };


      return {
        title: getTitle(),
        class: getClass(),
        src: getSrc(),
        srcSet: getSrcSet(),
        alt: getAlt(),
        price: getPrice(),
        total: getTotal(),
        cost: getCost()
      };
    },

    get getCartToggleAriaLabel() {
      return `${this.cartTotalQuantity()} ${this.cartTotalAmount()} - Open cart`;
    },

    async update(index, id, count) {
      this.loading = true;
      await this.handleUpdateCart(this.cartId.id, id, count);
      // this.count = this.getCurrentQuantity(index);
      this.loading = false;
    },

    remove(product) {
      this.handleRemoveItem(this.cartId.id, product.id, product.merchandise);
      // this.$refs.removeButton.classList.add("activated");
    },

    async handleRemoveItem(cartId, lineId, productMerchandise) {
      if (cartId) {
        try {
          // console.log(lineId)
          const data = await removeItemFromCart({
            cartId,
            lineId,
          });
          this.cartDetails = data.cartLinesRemove.cart;
          this.cartId.subtotal = this.cartDetails.estimatedCost.subtotalAmount.amount;
          this.cartId.totalQuantity = this.cartTotalQuantity();
          if (this.cartTotalQuantity() === 0) {
            this.cartAvailable = false;
          }
          this.recentlyAddedProductID = "";
          itemRemoved(productMerchandise)
          // itemRemoved(data.data.cartLinesAdd.cart.lines.edges[0].node.merchandise)
        } catch (error) {
          console.log(error);
        }
      }
    },

    async handleAddItem(cartId, itemId, quantity, isRelatedProduct = false) {
      // console.log(cartId, itemId, quantity)
      this.openCart();

      let parsedQuantity = parseInt(quantity);
      let data;

      if (cartId) {
        data = await addItemToCart({
          cartId,
          itemId,
          parsedQuantity,
        });

        // we want to bypass handling button states for related products
        if (!isRelatedProduct) {
          Alpine.store("product").handleButtonStates();
        }

        this.cartDetails = data.data.cartLinesAdd.cart;
        itemAdded(data.data.cartLinesAdd.cart.lines.edges[0].node.merchandise, parsedQuantity)
        this.cartId.subtotal = this.cartDetails.estimatedCost.subtotalAmount.amount;
        this.cartId.totalQuantity = this.cartTotalQuantity();

        this.recentlyAddedProductID = itemId;
      } else {
        data = await createCartWithItem({
          itemId,
          parsedQuantity,
        });

        // we want to bypass handling button states for related products
        if (!isRelatedProduct) {
          Alpine.store("product").handleButtonStates();
        }

        this.cartDetails = data.data.cartCreate.cart;
        this.cartId = {
          id: data.data.cartCreate.cart.id,
          totalQuantity: this.cartTotalQuantity(),
          subtotal: this.cartDetails.estimatedCost.subtotalAmount.amount,
        };
        this.recentlyAddedProductID = itemId;
        itemAdded(data.data.cartCreate.cart.lines.edges[0].node.merchandise, parsedQuantity)
      }
      this.cartAvailable = true;
    },

    async handleUpdateCart(cartId, lineId, quantity) {
      if (cartId) {
        try {
          data = await updateCart({
            cartId,
            lineId,
            quantity: parseInt(quantity),
          });

          this.cartDetails = data.data.cartLinesUpdate.cart;
          this.cartId.subtotal = this.cartDetails.estimatedCost.subtotalAmount.amount;
          this.cartId.totalQuantity = this.cartTotalQuantity();
          if (this.cartTotalQuantity() === 0) {
            this.cartAvailable = false;
          }
        } catch (error) {
          console.log(error);
        }
      }
    },

    cartTotalQuantity() {
      let totalItems = 0;
      if (this.cartDetails?.lines) {
        totalItems = this.cartDetails.lines.edges.reduce(function (acc, obj) {
          return acc + obj.node.quantity;
        }, 0);
      }
      return totalItems;
    },

    cartTotalAmount() {
      if (this.cartDetails?.estimatedCost) {
        return Alpine.store('utility').formatCurrencyString(Alpine.store('cart').cartDetails.estimatedCost.subtotalAmount.amount);
      }
      return Alpine.store('utility').formatCurrencyString(0.00);
    },

    itemTotalPrice(price, quantity) {
      const total = price * quantity;
      return formatCurrency(total);
    },

    handleClick(e) {
      const cart = document.getElementById("cart");
      if (!Alpine.store("cart").firstClickHandled) {
        Alpine.store("cart").firstClickHandled = true;
      } else if (!cart.contains(e.target)) {
        Alpine.store("cart").closeCart();
      }
    },

    openCart() {
      if (!this.open) {
        document.querySelector('#cart').classList.remove('hide');
        setTimeout(function() {
          document.body.classList.add("offscreen-nav-visible");
          document.body.addEventListener("click", this.handleClick);
          Alpine.store('cart').open = true;
        }, 50)
      }
    },

    closeCart() {
      this.firstClickHandled = false;
      if (this.open) {
        document.body.classList.remove("offscreen-nav-visible");
        document.body.removeEventListener("click", this.handleClick);
        this.open = false;
        setTimeout(function() {
          document.querySelector('#cart').classList.add('hide');
        }, 300)
      }
    },

    reorderCartProducts() {
      if (this.recentlyAddedProductID) {
        const index = this.cartDetails.lines.edges.findIndex(
          (item) => item.node.merchandise.id === this.recentlyAddedProductID
        );
        const removedItem = this.cartDetails.lines.edges.splice(index, 1);

        this.cartDetails.lines.edges.unshift(removedItem[0]);
      }

      return this.cartDetails.lines.edges;
    },
  });

  Alpine.store("product", {
    init() {
      this.productVariants = window.productEdges?.map(
        (variant, variantIndex) => {
          let variantOption = {};
          variant.selectedOptions.forEach((option, index) => {
            let optionName = option.name;
            let optionValue = option.value;
            variantOption[optionName] = optionValue;
          });
          return variantOption;
        }
      );

      // Get array of valid params and flatten
      let fullParamList = window.productEdges?.map((variant) => {
        let params = [];
        variant.selectedOptions.forEach((option) => {
          params.push(slugify(option.name));
        });
        return params;
      }).flat();

      this.paramList = [...new Set(fullParamList)];
    },
    productVariants: [],
    lastSelectedVariant: "",
    variantImageId: "",
    loading: false,
    added: false,
    variantState: {},
    paramList: [],

    get buttonTitle() {
      const title = !this.loading ? "ADD TO CART" : "ADDING";
      return title;
    },

    handleButtonStates() {
      this.toggleLoading();
    },

    toggleLoading() {
      this.loading = !this.loading;
    },

  });

  Alpine.store("utility", {
    formatCurrencyString(num) {
      return formatCurrency(num);
    },
  });

  Alpine.bind("AddButton", () => ({
    type: "button",

    "@click"() {
      Alpine.store("product").toggleLoading();
    },

    ":disabled"() {
      return Alpine.store("product").loading;
    },
  }));

  Alpine.data("addProductForm", () => ({
    init() {
      this.variantOptions = this.dedupeProductOptions(window.productEdges);
      this.showVariantSale()
    },
    addToCart(e, id) {
      Alpine.store("cart").handleAddItem(Alpine.store("cart").cartId.id, id, this.productVariantQuantity);
    },
    shouldShowTitle(title) {
      let shouldShow = true;
      if (title.toLowerCase() === "default title") {
        shouldShow = false;
      }
      return shouldShow;
    },
    showVariantSale(variant) {
      return this.variantSales.includes(variant);

    },
    dedupeProductOptions(variants) {
      let variantNames = [];
      let variantValues = [];
      let variantOptions = {};

      // Get variant category names and values, add to separate arrays
      variants.forEach((variant) => {
        if(variant.sale) {
          this.variantSales.push(variant.title)
        }
        variant.selectedOptions.forEach((option) => {
          variantNames.push(option.name);
          variantValues.push({
            name: option.name,
            value: option.value,
          });
        });
      });

      // Dedupe variant category names
      variantNames = [...new Set(variantNames)];
      // Add properties for each variant category
      variantNames.forEach((variant) => (variantOptions[variant] = []));
      // Add values to new properties
      variantValues.forEach((variant) => variantOptions[variant.name].push(variant.value));

      // Dedupe variant values
      for (const variant in variantOptions) {
        variantOptions[variant] = [...new Set(variantOptions[variant])];
      }
      return variantOptions;
    },
    productVariantQuantity: 1,
    variantOptions: [],
    variantSales: []
  }));

  Alpine.data(
    "productDetails",
    (
      initialVariantState = "",
      initialID = "",
      initialVariantID = "",
      initialPrice = 0
    ) => ({
      init() {
        // console.log(this.variantID)
        this.$nextTick(() => {
          Alpine.store("product").variantState = initialVariantState;

          // Set default variant states
          let filterName = Object.keys(initialVariantState)[0];
          let filterOption = Object.values(initialVariantState)[0];

          this.filterVariants(slugify(filterName), slugify(filterOption));
          this.filterByOption = filterName;

          this.params = new URLSearchParams(window.location.search);
          let validParams = [];

          for (const param of this.params) {
            if(Alpine.store('product').paramList.includes(param[0])) {
              validParams.push(param[0])
            }
          };
          this.validParams = validParams;

          // Convert parameters to string to see if any exist
          if (this.validParams.toString()) {
            this.setVariantFromParams(this.params);
            this.getVariant();
            return;
          } else {
            this.price = formatCurrency(initialPrice)
          }
        });
      },

      getVariants() {
        // Deep copy original product variants array, JSON parse/stringify method for deep copy only works with primitives FYI
        return JSON.parse(
          JSON.stringify(Alpine.store("product").productVariants)
        );
      },

      getSlugifiedVariant(variant) {
        // recreate the variant to be fully slugified
        const newVariant = {};
        for (const key in variant) {
          newVariant[slugify(key)] = slugify(variant[key]);
        }
        return newVariant;
      },

      filterVariants(filterName, filterValue) {
        let variants = this.getVariants();
        this.filteredVariants = variants.filter((variant) => {
          const newVariant = this.getSlugifiedVariant(variant);

          return newVariant[slugify(filterName)] === slugify(filterValue);
        });
      },

      setVariantFromParams(params) {
        if (params) {
          const selectedParams = {};

          for (const [paramKey, paramValue] of params.entries()) {
            if(Alpine.store('product').paramList.includes(paramKey)) {
              selectedParams[paramKey] = paramValue;
            }
          }

          this.filterVariants(
            Object.keys(selectedParams)[0],
            Object.values(selectedParams)[0]
          );

          let variants = this.getVariants();

          const selected = variants.filter((variant) => {
            const newVariant = this.getSlugifiedVariant(variant);
            return (
              // possible opportunity for more dynamic refactor
              newVariant[Object.keys(selectedParams)[0]] === Object.values(selectedParams)[0]);
          });
          Alpine.store("product").variantState = selected[0];
        }
      },

      async getVariant(e) {
        this.errors = null;
        try {
          // query for the variant id
          // If an event is passed, filter variants based on the variant category and value
          if (e) {
            this.filterVariants(
              slugify(e.target.name),
              slugify(e.target.value)
            );
            this.filterByOption = e.target.name;
          }

          // REVIEW IF STATEMENT FOR REFACTOR, MAY NOT BE NEEDED
          if (Alpine.store("product").variantState) {
            // Check validity of variantState
            let tempOption = Object.assign(
              {},
              Alpine.store("product").variantState
            );

            if (Object.values(tempOption)?.length > 1) {
              delete tempOption[this.filterByOption];
              const option = Object.entries(tempOption)[0];
              isValid = this.isValidOptionCombo(option[0], option[1]);
            } else {
              isValid = true;
            }

            // Redirect to a valid option combination
            if (!isValid) {
              Alpine.store("product").variantState = this.filteredVariants[0];

              this.errors = this.filterByOption;
            }

            const entries = Object.entries(
              Alpine.store("product").variantState
            );

            let values = [];
            entries.forEach((item) => {
              // change the query string
              this.params.set(slugify(item[0]), slugify(item[1]));
              history.replaceState(null, null, "?" + this.params.toString());

              let variantObject = {};
              variantObject.name = item[0];
              variantObject.value = item[1];

              values.push(variantObject);
            });
            // start the loader icon
            Alpine.store("cart").loading = true;
            const data = await getVariantByOptions(this.id, values);
            this.variantID = data.node.variantBySelectedOptions?.id;
            const amount =  await data.node.variantBySelectedOptions.priceV2.amount;
            const compareAtAmount =  await data.node.variantBySelectedOptions.compareAtPrice?.amount;
            this.price = formatCurrency(amount);
            if(compareAtAmount) {
              this.compareAtPrice = formatCurrency(compareAtAmount);
            } else {
              this.compareAtPrice = 0;
            }
            Alpine.store("product").variantImageId = await data.node.variantBySelectedOptions.image.id;
            Alpine.store("cart").loading = false;
          }
        } catch (error) {
          console.log(error);
        }
      },

      isValidOptionCombo(name, value) {
        return this.filteredVariants.some((variant) => {
          if (name == this.filterByOption) {
            return true;
          }
          let tempVariant = { ...variant };
          delete tempVariant[this.filterByOption];
          return Object.values(tempVariant).includes(value);
        });
      },

      checked: false,
      id: initialID,
      variantID: initialVariantID,
      params: null,
      filterByOption: "",
      filteredVariants: [],
      price: '$',
      compareAtPrice: 0,
      errors: null,
    })
  );

  Alpine.data("addSingleProduct", (variantId) => ({
    async init() {
      this.selectedVariant = `gid://shopify/ProductVariant/${variantId}`;
    },
    addToCart(e) {
      Alpine.store("cart").handleAddItem(
        Alpine.store("cart").cartId.id,
        this.selectedVariant,
        this.productVariantQuantity
      );
    },
    selectedVariant: "",
    productVariantQuantity: 1,
  }));
});
