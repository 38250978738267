// Analytics data
export function formatItems (items) {
  return items.map(function (item, index) {
    return {
      item_name: (`${item.product.title} - ${item.title}`).replace(' - Default Title', ''),
      item_id: item.sku,
      currency: "USD",
      price: parseFloat(item.priceV2.amount),
      quantity: item.quantity,
      index: index+1
    };
  });
}

export function itemAdded(item, quantity){
  item.quantity = quantity;
  const obj = {
    event: 'add_to_cart',
    ecommerce: {
      items: formatItems([item])
    }
  };
  // console.log("add_to_cart", obj);
  if(window.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(obj);
  }
}

export function itemRemoved(item){
  const obj = {
    event: 'remove_from_cart',
    ecommerce: {
      items: formatItems([item])
    }
  };
  // console.log("remove_from_cart", obj);
  if(window.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(obj);
  }
}