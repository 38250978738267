export function slugify(text) {
  return text
    .toString() // Cast to string (optional)
    .normalize("NFKD") // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
    .toLowerCase() // Convert the string to lowercase letters
    .trim() // Remove whitespace from both sides of a string (optional)
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars
    .replace(/\-\-+/g, "-"); // Replace multiple - with single -
}

export function reverseSlugify(text, capitalizeAllWords = false) {
  const modified = text
    .toString() // Cast to string (optional)
    .normalize("NFKD") // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
    .trim() // Remove whitespace from both sides of a string (optional)
    .replace(/\-/g, " "); // Replace - with a space

  const capitalized = modified.charAt(0).toUpperCase() + modified.slice(1);

  //split the above string into an array of strings
  //whenever a blank space is encountered

  const arr = modified.split(" ");

  //loop through each element of the array and capitalize the first letter.

  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  //Join all the elements of the array back into a string
  //using a blankspace as a separator
  const capitalizeAll = arr.join(" ");

  return capitalizeAllWords ? capitalizeAll : capitalized;
}

export const formatCurrency = (num) => {
  const safeNumber = parseFloat(num);
  return safeNumber.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};
