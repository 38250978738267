const shopifyFetch = async ({
  query,
  variables,
  endpoint = "https://shop.standleeforage.com/api/2023-10/graphql.json",
  accessToken = "e4afe408c18a056d79443e7414c59a3b",
}) => {
  try {
    const result = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Shopify-Storefront-Access-Token": accessToken
      },
      body: JSON.stringify({ query, variables }),
    });
    return checkStatus(result);
  } catch (error) {
    console.log(error);
  }
};

async function checkStatus(response) {
  if (response.ok) {
    const responseData = await response.json();
    if (!responseData.data) {
      const emptyResultsError = new EmptyResultsError("No results found.");
      emptyResultsError.response = response;
      throw emptyResultsError;
    } else {
      return await responseData;
    }
  } else if (response.status === 401 || response.status === 403) {
    const unauthorizedError = new UnauthorizedError(response.statusText);
    unauthorizedError.response = response;
    throw unauthorizedError;
  } else if (response.status === 404) {
    const notFoundError = new NotFoundError(response.statusText);
    NotFoundError.response = response;
    throw notFoundError;
  } else if (response.status === 409) {
    throw new ConflictError(response.statusText);
  }
}

// utility functions for error handling and validating results
class UnauthorizedError extends Error {
  constructor(message) {
    super(message);
    this.name = "UnauthorizedError";
    this.message = message;
  }
}

class ConflictError extends Error {
  constructor(message) {
    super(message);
    this.name = "ConflictError";
    this.message = message;
  }
}

class NotFoundError extends Error {
  constructor(message) {
    super(message);
    this.name = "NotFoundError";
    this.message = message;
  }
}

class EmptyResultsError extends Error {
  constructor(message) {
    super(message);
    this.name = "EmptyResultsError";
    this.message = message;
  }
}

export { shopifyFetch };